$primary: #e6833b; // Update $primary to modify primary color for the webpage
$secondary: #ffc484;

.nav-link {
    transition: text-decoration 0.3s ease-in-out;  // Smooth transition for hover effect

    &:hover, &:focus {
        text-decoration: underline;  // Underline on hover and focus
    }
}

.btn {
    transition: text-decoration 0.3s ease-in-out;  // Smooth transition for hover effect

    &:hover, &:focus {
        text-decoration: underline;  // Underline on hover and focus
    }
}

.bg-primary {
    background-color: linear-gradient(to right, rgb(237, 34, 36), rgb(243, 91, 34), rgb(249, 150, 33), rgb(245, 193, 30), rgb(241, 235, 27) 27%, rgb(241, 235, 27), rgb(241, 235, 27) 33%, rgb(99, 199, 32), rgb(12, 155, 73), rgb(33, 135, 141), rgb(57, 84, 165), rgb(97, 55, 155), rgb(147, 40, 142));
}

a {
    color: var(--primary);
    text-decoration: none !important;
    font-weight: bold;
}

a:hover {
    text-decoration: underline !important;
}

.pagination {
    margin-bottom: 0;
}

.no-arrow-dropdown .dropdown-toggle::after {
    display: none !important;
}

@import '~bootstrap/scss/bootstrap';
